import type { DropdownProps, ShorthandCollection } from '@archipro-design/aria';

import { pxArrayToRem, useTheme } from '@archipro-design/aria';
import { useAppDisplayModeContext } from '@archipro-design/aria';
import { Button, pxToRem } from '@archipro-design/aria';
import { TextInput } from '@archipro-design/aria';
import { TextSelectorInput, useStyles } from '@archipro-design/aria';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFetcher, useLocation } from '@remix-run/react';
import { useLatest, useMount } from 'ahooks';

import { Controller, useForm } from 'react-hook-form';
import { useEnquirySession } from '../../hook/use-enquiry-session';
import type { BasicPlace } from '~/modules/location/type';
import { usePlaceFinder } from '~/modules/location/hook/use-place-finder';

import * as S from './EnquiryForm.style';
import type { Branch } from '../../util/extract-branch-from-network.server';

import { useEffect, useState } from 'react';
import type { SubmitActionResponse } from '~/routes/remix-api.enquiry.submit';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import {
    assertErrorResponse,
    assertSuccessResponse,
} from '~/modules/root/graphql/responses';
import { formatEnquiryPayload } from '../../util/format-enquiry-payload';
import { trackBrochureRequest } from '~/modules/tracking/util/trackBrochureRequest';
import { useTracker } from '@archipro-website/tracker';
import { useDirectoryURLType } from '~/modules/directory/hook/use-directory-url-type';
import type { CreateEnquirySource } from '..';
import { trackSubmitCreateEnquiryRequest } from '~/modules/tracking/util/trackSubmitCreateEnquiryRequest';
import { trackCreatedEnquiry } from '~/modules/tracking/util/trackCreatedEnquiry';
import { useEnquiryForm } from '../../hook/use-enquiry-form';
import type { FavouriteItem } from '~/modules/design-board/type';
import SaveToDesignBoard from '~/modules/design-board/component/SaveToDesignBoard';
import type { Directory } from '~/modules/directory/type';
import { trackProjectEnquire } from '~/modules/tracking/util/trackProjectEnquiry';
import { useUser } from '~/modules/user';
import { formatSuburbDropdownOptions } from '~/modules/enquiry/util/format-place-dropdown-options';
import {
    getClosestBasicPlace,
    mapToBasicPlaces,
} from '~/modules/location/util';
import { fetcherIsDone } from '~/utils/fetcherHelper';
import type { ProfessionalInitialResult } from '~/modules/professional/hook/use-professional-initial-route-data';
import type { EnquiryFormShape } from '../enquiry-validation/enquiryValidation';
import { useEnquiryFormValidator } from '../enquiry-validation/enquiryValidation';
import { trackAuthEvent } from '~/modules/user/util/track-auth-event';

interface EnquiryItemProps {
    id: number;
    type: Directory;
    title: string;
    category: string;
}

// eslint-disable-next-line import/no-unused-modules
export type EnquiryProfessional = Pick<
    ProfessionalInitialResult,
    | 'ID'
    | 'Title'
    | 'CompanyLogo'
    | 'LibraryLocations'
    | 'LogoBackgroundColor'
    | 'Link'
    | 'Website'
    | 'branchOptions'
    | 'ArchiproEditorialAccount'
    | 'primaryCTA'
    | 'CustomEnquiryMessage'
    | 'AvailableForWork'
>;

export interface BookConsultationProfessional extends EnquiryProfessional {
    TeamCalendarURLSegment?: string;
}

export interface EnquiryFormProps {
    professional: EnquiryProfessional;
    branchOptions: DropdownItem<Branch>[];
    disableSubmit?: boolean;
    disableSaveToDesignBoard?: boolean;
    onSuccess?: () => void;
    onFailure?: (publicUser?: PublicUser) => void;
    onSendClick?: () => void;
    source?: CreateEnquirySource;
    submitButtonText?: string;
    favouriteItem?: FavouriteItem;
    siteTreeID?: number;
    messageBodyPlaceholder?: string;
    relatedItem?: EnquiryItemProps;
}

export interface DropdownItem<T> {
    header: string;
    value: T;
    selected?: boolean;
}

const INPUT_DEFAULT_VARIABLES = {
    inputMarginLargeDesktop: pxArrayToRem([24, 20]),
    inputMarginMediumDesktop: pxArrayToRem([24, 20]),
    inputMarginMobile: pxArrayToRem([14, 13]),
    hoverBackgroundColor: 'transparent',
    errorColor: 'red',
};

// Note: Enquiry Side effects (sending and redirecting are handled in useEnquirySession)
export const EnquiryForm = (props: EnquiryFormProps) => {
    const {
        professional,
        branchOptions,
        disableSubmit,
        disableSaveToDesignBoard,
        onSuccess,
        onFailure,
        source = 'InPage',
        favouriteItem,
        submitButtonText = 'SEND',
        messageBodyPlaceholder,
        siteTreeID,
        relatedItem,
    } = props;

    const { mobile } = useAppDisplayModeContext();
    const tracker = useTracker();
    const location = useLocation();
    const user = useUser();
    const isLoggedIn = user.__typename === 'Me';
    const hasEmptyLastName = isLoggedIn && !user.LastName;
    const hasEmptyPhoneNumber = isLoggedIn && !user.Phone;

    const { css } = useStyles({
        visibleRowsSuburbDropdown: 5,
        visibleRowsBranchDropdown: 8,
        isMobile: mobile,
    });

    const {
        enquirySession,
        newEnquiryDefault,
        resumeEnquiryDefault,
        setEnquirySession,
        setAutoSubmit,
        autoSubmitLocalValue,
    } = useEnquirySession(undefined, source);

    const directoryType = useDirectoryURLType();

    const { renderBranchOptions } = useEnquiryForm(directoryType);

    let defaultValues = newEnquiryDefault;

    if (professional.ID === enquirySession?.siteTreeId) {
        defaultValues = resumeEnquiryDefault;
    }

    const defaultBranch = branchOptions?.find(
        (branch) => branch.value.branchId === professional.ID
    );

    const formValidator = useEnquiryFormValidator(
        branchOptions,
        hasEmptyLastName
    );

    const [openBranchDropdown, setOpenBranchDropdown] = useState(false);

    const fetcher = useFetcher<SubmitActionResponse>();

    const { control, setValue, getValues, handleSubmit, reset } =
        useForm<EnquiryFormShape>({
            resolver: zodResolver(formValidator),
            mode: 'all',
        });

    // To prevent hydration errors, load default values after intial render.
    // ToDo: Move unsent enquiry sessions to cookies
    useMount(() => {
        reset({
            ...defaultValues,
            branch: defaultBranch,
            siteTreeId: siteTreeID ?? professional.ID,
            shareDetails: true,
        });
    });

    const { changeKeyword: findPlace, data: places } = usePlaceFinder({
        limit: 10,
    });

    // todo: use the dropdownOptions in the usePlaceFinder when there's a comming change
    const placeOptions = formatSuburbDropdownOptions(mapToBasicPlaces(places));

    const saveStateToSession = () => {
        setEnquirySession({ ...getValues() });
    };

    const setBranchBySuburbField = (input: DropdownItem<BasicPlace>) => {
        const referencePlace = input.value;
        const places = branchOptions.map((p) => p.value);
        const closestPlace = getClosestBasicPlace(referencePlace, places);
        const closestBranch = branchOptions.find((branch) => {
            return branch.value === closestPlace;
        });
        setValue('branch', closestBranch);
    };

    const stableSetAutoSubmit = useLatest(setAutoSubmit);

    const onSubmit = async (data: EnquiryFormShape) => {
        if (fetcher.state !== 'idle') return;
        const payload = formatEnquiryPayload(data);

        tracker.log('EnquirySubmit', {
            url: new URL(window.location.href),
            label: professional.Title,
        });

        trackSubmitCreateEnquiryRequest(tracker, {
            source: source,
        });

        if (directoryType === 'professional' || directoryType === 'product') {
            trackBrochureRequest(tracker, {
                type: directoryType,
                region: payload.suburbPostcode,
            });
        } else if (directoryType === 'project' && relatedItem) {
            trackProjectEnquire(tracker, {
                category: relatedItem.category,
                step: 2,
                project: relatedItem.title,
                professional: professional.Title,
                region: payload.suburbPostcode,
            });
        }

        if (autoSubmitLocalValue) {
            stableSetAutoSubmit.current?.(false);
        }

        fetcher.submit(payload, {
            method: 'post',
            action: '/remix-api/enquiry/submit',
        });

        if (source != 'EnquiryModal') {
            tracker.log('OnPageEnquirySend', {
                url: new URL(window.location.href),
                targetTracker: 'archiproTracker',
            });
        }
    };

    const stableOnSuccess = useLatest(onSuccess);
    const stableOnFailure = useLatest(onFailure);

    useEffect(() => {
        const success =
            fetcher?.data &&
            assertSuccessResponse(fetcher.data) &&
            fetcherIsDone(fetcher);
        const error =
            fetcher?.data &&
            assertErrorResponse(fetcher.data) &&
            fetcherIsDone(fetcher);
        if (success) {
            stableOnSuccess.current?.();
            trackCreatedEnquiry(tracker, { source });

            if (
                fetcher.data &&
                'data' in fetcher.data &&
                fetcher.data.data &&
                'GeneratedMember' in fetcher.data.data &&
                fetcher.data.data.GeneratedMember
            ) {
                trackAuthEvent({
                    tracker,
                    event: 'new_account_registered',
                    provider: 'AutoGenerated',
                    authSource: 'enquiry',
                    guestID: fetcher.data.data.GeneratedMember.TrackedGuest?.ID,
                });
            }
        }

        if (error) {
            setEnquirySession({ ...getValues() });
            const publicUser =
                (fetcher.data &&
                    'publicUser' in fetcher.data &&
                    fetcher.data?.publicUser) ||
                undefined;
            if (publicUser) {
                stableSetAutoSubmit.current?.(true);
            }
            stableOnFailure.current?.(publicUser);
        }
    }, [
        fetcher,
        stableOnSuccess,
        stableOnFailure,
        stableSetAutoSubmit,
        setEnquirySession,
        getValues,
        tracker,
        source,
    ]);

    useEffect(() => {
        setValue('message', '');
    }, [location, setValue]);

    const theme = useTheme();

    return (
        <fetcher.Form
            id="enquiryForm"
            method="post"
            onSubmit={handleSubmit((data) => onSubmit(data))}
            action={'/remix-api/enquiry/submit'}
            className={css(S.EnquiryForm)}
            noValidate={true}
        >
            <div className={css(S.EnquiryGrid)}>
                <Controller
                    control={control}
                    name={'name'}
                    render={({ field, fieldState: { error } }) => (
                        <TextInput
                            input={{
                                ...field,
                                id: 'name-input',
                                placeholder: 'Full name*',
                                required: true,
                                onBlur: () => {
                                    field.onBlur();
                                    saveStateToSession();
                                },
                                input: {
                                    className: 'data-hj-allow',
                                },
                            }}
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            variables={INPUT_DEFAULT_VARIABLES}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={'email'}
                    render={({ field, fieldState: { error } }) => (
                        <TextInput
                            input={{
                                ...field,
                                id: 'email-input',
                                placeholder: 'Email*',
                                required: true,
                                disabled: isLoggedIn && !!defaultValues.email,
                                onBlur: () => {
                                    field.onBlur();
                                    saveStateToSession();
                                },
                                input: {
                                    className: 'data-hj-allow',
                                },
                                // className: 'data-hj-allow',
                            }}
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            variables={INPUT_DEFAULT_VARIABLES}
                        />
                    )}
                />

                <Controller
                    control={control}
                    name={'phoneNumber'}
                    render={({ field, fieldState: { error } }) => (
                        <TextInput
                            input={{
                                ...field,
                                id: 'phoneNumber-input',
                                placeholder: 'Phone number*',
                                required: true,
                                disabled: isLoggedIn && !hasEmptyPhoneNumber,
                                onBlur: () => {
                                    field.onBlur();
                                    saveStateToSession();
                                },
                                className: 'data-hj-allow',
                            }}
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            variables={INPUT_DEFAULT_VARIABLES}
                        />
                    )}
                />

                <Controller<EnquiryFormShape>
                    control={control}
                    name={'suburbPostcode'}
                    render={({
                        field: { onBlur, ref, value },
                        fieldState: { error },
                    }) => (
                        <TextSelectorInput
                            className={css(S.SuburbDropdown)}
                            input={{
                                ref,
                                placeholder: 'Suburb / Postcode*',
                            }}
                            dropdown={{
                                value,
                                defaultSearchQuery:
                                    (
                                        value as EnquiryFormShape['suburbPostcode']
                                    )?.header ??
                                    defaultValues?.suburbPostcode?.header,
                                items: placeOptions.map((item) => {
                                    if (item.header === value) {
                                        item.selected = true;
                                    } else {
                                        item.selected = false;
                                    }
                                    return item;
                                }),
                                noResultsMessage: 'No results found.',
                                onSearchQueryChange: (_, data) => {
                                    findPlace(data.searchQuery);
                                },
                                search: (
                                    items: ShorthandCollection<
                                        DropdownProps['items']
                                    >
                                ) => items,
                                onChange: (_, data) => {
                                    const place =
                                        data.value as EnquiryFormShape['suburbPostcode'];

                                    setValue(
                                        'suburbPostcode',
                                        place ?? undefined
                                    );
                                    if (place) {
                                        setBranchBySuburbField(place);
                                    }
                                },
                                offset: [0, 20],
                                onBlur: () => {
                                    onBlur();
                                    saveStateToSession();
                                },
                                searchInput: {
                                    input: {
                                        className: 'data-hj-allow',
                                    },
                                },
                            }}
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            variables={INPUT_DEFAULT_VARIABLES}
                        />
                    )}
                />
            </div>

            {renderBranchOptions && branchOptions.length > 0 && (
                <Controller<EnquiryFormShape>
                    control={control}
                    name={'branch'}
                    render={({
                        field: { value, onBlur, ref },
                        fieldState: { error },
                    }) => (
                        <TextSelectorInput
                            className={css(S.BranchDropdown)}
                            input={{
                                ref,
                                placeholder: 'Select branch',
                                required: true,
                            }}
                            showChevronIcon={true}
                            dropdown={{
                                open: openBranchDropdown,
                                value,
                                items: branchOptions.map((branch) => {
                                    const active =
                                        value as EnquiryFormShape['branch'];
                                    const selected =
                                        branch.header === active?.header;
                                    return { selected, ...branch };
                                }),
                                onChange: (_, data) => {
                                    const branch =
                                        data.value as EnquiryFormShape['branch'];
                                    setValue('branch', branch ?? undefined);
                                },
                                onOpenChange: (_, data) => {
                                    data.open !== undefined &&
                                        setOpenBranchDropdown(data.open);
                                },
                                onBlur: () => {
                                    onBlur();
                                    saveStateToSession();
                                },
                                clearIndicator: '',
                                searchInput: {
                                    input: {
                                        className: 'data-hj-allow',
                                    },
                                },
                            }}
                            state={error?.message ? 'error' : 'default'}
                            message={error?.message}
                            variables={INPUT_DEFAULT_VARIABLES}
                        />
                    )}
                />
            )}
            <Controller
                control={control}
                name={'message'}
                render={({ field, fieldState: { error } }) => (
                    <TextInput
                        className={css(S.MessageInput)}
                        input={{
                            ...field,
                            id: 'message-input',
                            placeholder:
                                messageBodyPlaceholder ??
                                'Please be descriptive with your enquiry to ensure a good response.',
                            required: true,
                            input: {
                                as: 'textarea',
                                className: 'data-hj-allow',
                            },
                            onBlur: () => {
                                field.onBlur();
                            },
                        }}
                        variables={INPUT_DEFAULT_VARIABLES}
                        state={error?.message ? 'error' : 'default'}
                        message={error?.message}
                    />
                )}
            />

            <div className={css(S.SubmitButton)}>
                {favouriteItem && (
                    <SaveToDesignBoard
                        {...favouriteItem}
                        disable={disableSaveToDesignBoard}
                        heartButton={{
                            disabled: disableSaveToDesignBoard,
                            size: 32,
                            color: 'primary',
                            variables: {
                                buttonPadding: pxToRem(15),
                                iconPadding: 0,
                                primaryNoBKGButtonBorderNormal: `1px solid #222222`,
                                primaryNoBKGButtonBorderHover: `1px solid #222222`,
                                primaryNoBKGButtonBackgroundColorHover:
                                    theme.siteVariables.colors.white['200A'],
                            },
                        }}
                    />
                )}
                <Button
                    size={16}
                    key={'enquiry-submit-button'}
                    type={'submit'}
                    color={'dark'}
                    loading={
                        fetcher.state === 'submitting' ||
                        fetcher.state === 'loading'
                    }
                    {...(mobile && { fluid: true })}
                    variables={{
                        iconPadding: 0,
                        buttonPadding: pxToRem(24),
                        buttonMinWidth: pxToRem(212),
                        buttonSize16LetterSpacing: '-0.02em',
                        darkButtonBackgroundColorNormal: '#222222',
                    }}
                    onClick={props.onSendClick}
                    disabled={!!disableSubmit || fetcher.state !== 'idle'}
                >
                    {submitButtonText.toUpperCase()}
                </Button>
            </div>
        </fetcher.Form>
    );
};

export default EnquiryForm;
